import { Button, Collapse, Form, Input, message, Modal, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
const { Panel } = Collapse;
import PropTypes from 'prop-types';
import GoogleAutoComplete from 'react-google-autocomplete';

import {
  checkZipCode,
  getCustomerAddress,
  getCustomerStorageOrders,
  getCustomerStorageSubscriptions,
  updateCustomerAddress
} from '../../../../data/API';
import { getMyData } from '../../../../utils/token';
import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

const Account = (props) => {
  const [openEditMdl, setOpenEditMdl] = useState(false);
  const [openPytMdl, setOpenPytMdl] = useState(false);

  const [orders, setOrders] = useState({
    currentPage: 1,
    limit: 6,
    orders: [],
    pages: 0
  });
  const [subscriptions, setSubscriptions] = useState({
    currentPage: 1,
    limit: 8,
    subscriptions: [],
    pages: 0
  });

  const [showLoader, setShowLoader] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [zipCodeError, setZipCodeError] = useState(null);
  const [deliveryAddressError, setDeliveryAddressError] = useState(null);

  let { customerData, getUserData, extraWork } = props;

  const { user } = useSelector((state) => state.auth);
  let customer = getMyData();

  const [form] = Form.useForm();

  const onFinish = () => {
    message.success('Submit success!');
  };
  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  useEffect(() => {
    if (customerData) setShowLoader(true);
  }, [customerData]);

  const getSubscriptions = async () => {
    let response = await getCustomerStorageSubscriptions(customer.id, {
      offset: subscriptions?.currentPage == 1 ? undefined : (subscriptions?.currentPage - 1) * subscriptions?.limit,
      limit: subscriptions?.limit
    });

    if (response?.data?.success) {
      setSubscriptions((prev) => ({
        ...prev,
        pages: Math.ceil(response?.data.data.totalCount / subscriptions?.limit),
        subscriptions: subscriptions?.currentPage == 1 ? [...response.data.data.subscriptions] : [...subscriptions.subscriptions, ...response.data.data.subscriptions] || []
      }));
    }
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNum = value.replace(/[^\d]/g, '');
    const phoneLen = phoneNum.length;

    if (phoneLen < 4) return phoneNum;

    if (phoneLen < 7) {
      return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;
    }

    return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(6, 10)}`;
  };

  const validateMobileNumber = (e) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handlePrimary = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    form.setFieldsValue({
      mobile_number: formattedPhone
    });
  };

  const handleSecondary = (e) => {
    console.log(e, 'e');
    const formattedPhone = formatPhoneNumber(e.target.value);
    form.setFieldsValue({
      secondary_number: formattedPhone
    });
  };

  const getOrders = async () => {
    let response = await getCustomerStorageOrders(customer.id, {
      offset: orders?.currentPage == 1 ? undefined : (orders?.currentPage - 1) * orders?.limit,
      limit: orders?.limit
    });

    if (response?.data?.success) {
      setOrders((prev) => ({
        ...prev,
        pages: Math.ceil(response?.data?.data?.totalCount / orders?.limit),
        orders: orders?.currentPage == 1 ? [...response.data.data.orders] : [...orders.orders, ...response.data.data.orders] || []
      }));
    }
  };

  const editCustomer = () => {
    setOpenEditMdl(true);

    form.setFieldsValue({
      name: customerData?.name,
      email: customerData?.email,
      mobile_number: customerData?.custormerData?.mobile
        ? formatPhoneNumber(customerData?.custormerData?.mobile)
        : '',
      secondary_number: customerData?.custormerData?.secondary_mobile
        ? formatPhoneNumber(customerData?.custormerData?.secondary_mobile)
        : '',
      // delivery_addr: customerData?.custormerData?.address
      //   ? customerData?.custormerData?.address
      //   : '',
      apt_number: customerData?.custormerData?.apt_number
        ? customerData?.custormerData?.apt_number
        : '',
      zip_code: customerData?.custormerData?.zipcode ? customerData?.custormerData?.zipcode : '',
      extra_work_id: customerData?.custormerData?.extra_work_id
        ? customerData?.custormerData?.extra_work_id
        : ''
    });

    setDeliveryAddress(
      customerData?.custormerData?.address ? customerData?.custormerData?.address : ''
    );
  };

  useEffect(() => {
    getOrders();
  }, [orders?.currentPage]);

  const getOrderStatus = (st) => {
    let status = 'Pending';

    switch (st) {
      case 'PENDING':
        status = 'Pending';
        break;
      case 'CONFIRMED':
        status = 'Confirmed';
        break;
      case 'COMPLETED':
        status = 'Completed'
        break;
    }
    return status;
  };

  const handleCancel = () => {
    setOpenEditMdl(false);
    form.resetFields();
  };

  const onSubmitCustomer = async () => {
    try {
      const formValues = await form.validateFields();
      
      setIsFormSubmitted(true);
      
      if (zipCodeError) {
        setIsFormSubmitted(false);
        return;
      }
      
      let payload = {
        mobile: formValues?.mobile_number
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '')
          .replace('-', ''),
        address: deliveryAddress
      };
      
      if (formValues.secondary_number) {
        payload['secondary_mobile'] = formValues?.secondary_number
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '')
          .replace('-', '');
      }
      
      if (formValues.apt_number) {
        payload['apt_number'] = formValues.apt_number;
      }
      
      if (formValues.zip_code) {
        payload['zipcode'] = formValues.zip_code;
      }
      
      if (formValues.extra_work_id) {
        payload['extra_work_id'] = formValues.extra_work_id;
      }
      
      const response = await updateCustomerAddress(payload);
      
      if (response?.data?.success) {
        setOpenEditMdl(false);
        setIsFormSubmitted(false);
        message.success('Customer updated successfully...!');
        
        if (response?.data?.data) {
          getUserData(response?.data?.data);
        }
      } else {
        setIsFormSubmitted(false);
        message.error('Unable to update customer, Please try again...!');
      }
    } catch (err) {

      setIsFormSubmitted(false);
      
      if (!err.errorFields) {
        message.error('Unable to update customer, Please try again...!');
      }
    }
  };

  const onAddresschange = (e) => {
    if (deliveryAddress && e.target.value == 0) {
      setDeliveryAddress('');
      setDeliveryAddressError('Please Select Delivery Address');
    }
  };

  const setAddress = async (place) => {
    setDeliveryAddress(place?.formatted_address);

    let postal, country, city, state;
    place.address_components.forEach((address_element) => {
      if (address_element.types.includes('postal_code')) postal = address_element.long_name;
      if (address_element.types.includes('country')) country = address_element.short_name;
      if (address_element.types.includes('locality')) city = address_element.long_name;
      if (address_element.types.includes('administrative_area_level_1'))
        state = address_element.long_name;
    });

    const address = {
      city: city,
      country: country,
      line1: place?.formatted_address,
      state: state,
      postal: postal
    };

    let zipCodeRes = await checkZipCode({ deliveryzipcode: postal ? postal : '781006' });

    if (zipCodeRes?.data?.success === true) {
      setZipCodeError(null);
    } else {
      setZipCodeError(zipCodeRes?.data?.message_2);
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, [subscriptions?.currentPage])

  const handleSubPagination = (page) => {
    setSubscriptions(prev => ({
      ...prev,
      currentPage: page
    }))
  }

  const handleOrdersPagination = (page) => {
    setOrders(prev => ({
      ...prev,
      currentPage: page
    }))
  }

  return (
    <>
      <Spin spinning={!showLoader} tip={'fetching user data.....'}>
        <div className="user-info py-3 px-3 s-color-black ">
          <Row className="align-items-start justify-content-center px-2">
            <Col
              xxl={2}
              xl={2}
              lg={3}
              md={4}
              sm={11}
              xs={11}
              className={`h-100 py-3 px-0 mx-3 cursor-pointer`}>
              <p className="mb-0 pb-1 info-title">Personal Details</p>
              <p className="mb-0 pb-1 info-details">
                <span className="fw-400">Name:</span>
                <span className="fw-600"> {customerData?.name}</span>
              </p>
              <p className="mb-0 pb-1 info-details">
                <span className="fw-400">Email:</span>
                <span className="fw-600"> {customerData?.email}</span>
              </p>
              <p className="mb-0 pb-1 info-details">
                <span className="fw-400">Mobile number:</span>
                <span className="fw-600">
                  {' '}
                  {customerData?.custormerData?.mobile ? customerData?.custormerData?.mobile : '-'}
                </span>
              </p>
              <p className="mb-0 pb-1 info-details">
                <span className="fw-400">Secondary Mobile Number:</span>
                <span className="fw-600">
                  {' '}
                  {customerData?.custormerData?.secondary_mobile
                    ? customerData?.custormerData?.secondary_mobile
                    : '-'}
                </span>
              </p>
              <p className="mb-0 pb-1 info-details">
                <span className="fw-400">Address:</span>
                <span className="fw-600">
                  {' '}
                  {customerData?.custormerData?.address
                    ? customerData?.custormerData?.address
                    : '-'}
                </span>
              </p>
              <p className="mb-0 pb-1 info-details">
                <span className="fw-400">Apt. #:</span>
                <span className="fw-600">
                  {' '}
                  {customerData?.custormerData?.apt_number
                    ? customerData?.custormerData?.apt_number
                    : '-'}
                </span>
              </p>
              <p className="mb-0 pb-1 info-details">
                <span className="fw-400">Zip Code:</span>
                <span className="fw-600">
                  {' '}
                  {customerData?.custormerData?.zipcode
                    ? customerData?.custormerData?.zipcode
                    : '-'}
                </span>
              </p>
              <p className="mb-0 pb-1 info-details">
                <span className="fw-400">Stairs or Elevator:</span>
                <span className="fw-600">
                  {' '}
                  {customerData?.custormerData ? (customerData?.custormerData?.extra_work_id &&
                    customerData?.custormerData?.extra_work_id == 1)
                    ? 'No'
                    : 'Yes' : '-'}
                </span>
              </p>

              <Button
                type="primary"
                size="large"
                className="mt-3 info-btn"
                ghost
                onClick={() => editCustomer()}>
                <i className="fa fa-edit mr-2"></i> Edit
              </Button>
            </Col>

            <hr className="info-divider w-100 px-3" />

            <Col
              xxl={2}
              xl={2}
              lg={3}
              md={4}
              sm={11}
              xs={11}
              className={`h-100 py-3 px-0 mx-3 cursor-pointer`}>
              {/* <p className="mb-0 pb-1 info-title">Payment Details</p>
            <p className="mb-0 pb-1 info-details">
              <span className="fw-400">Name on card:</span>
              <span className="fw-600"> John Smith</span>
            </p>
            <p className="mb-0 pb-1 info-details">
              <span className="fw-400">Card:</span>
              <span className="fw-600"> **** **** **** 4567</span>
            </p>
            <p className="mb-0 pb-1 info-details">
              <span className="fw-400">Zip code:</span>
              <span className="fw-600"> 12345</span>
            </p>

            <Button
              onClick={() => setOpenPytMdl(true)}
              type="primary"
              size="large"
              className="mt-3 info-btn"
              ghost>
              <i className="fa fa-edit mr-2"></i> Edit
            </Button> */}
            </Col>
          </Row>
        </div>

        <div className="user-subscription s-color-black">
          <Container className="pt-4 pb-5 px-4">
            <p className="mb-0 pb-1 py-3 title fw-600">Your subscriptions</p>

            {subscriptions?.subscriptions?.length == 0 ? (
              <>
                <p>No subscriptions found</p>
              </>
            ) : (
              <>
                <Row>
                  {subscriptions?.subscriptions?.map((obj, index) => {
                    return (
                      <>
                        <Col xxl={3} xl={3} lg={4} md={6} sm={12} xs={12}>
                          <div className="order-card">
                            <p className="order-info fw-400 mb-0 pb-1 fw-600 color-primary ">
                              {obj.order.order_number}
                            </p>
                            <p className="order-info fw-400 mb-0 pb-1">
                              Subscription start:{' '}
                              {moment(obj.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                            </p>
                            <p className="order-info fw-400 mb-0 pb-1">
                              Next billing date:{' '}
                              {moment(obj.next_billing_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                            </p>
                            <p className="order-info fw-400 mb-0 pb-1">
                              Amount: ${obj?.amount ? obj.amount : 0}/month
                            </p>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                  {subscriptions?.pages > 1 ? (
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                      <div className="d-flex justify-content-center align-items-center">
                        {
                          subscriptions?.currentPage > 1 ?
                            <p className="mb-0 show-more-btn mr-2" onClick={() => handleSubPagination(1)}>
                              show less <UpOutlined size={'small'} />
                            </p>
                            : ''
                        }
                        {subscriptions?.currentPage <= subscriptions.pages ? (
                          <p className="mb-0 show-more-btn ml-2" onClick={() => handleSubPagination(subscriptions?.currentPage + 1)}>
                            show more <DownOutlined size={'small'} />
                          </p>
                        ) : ''}

                      </div>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </>
            )}
          </Container>
        </div>

        <div className="user-orders s-color-black">
          <Container className="pt-4 pb-5 px-4">
            <p className="mb-0 pb-1 py-3 title fw-600">Your orders</p>

            {/* {orders?.orders?.length == 0 ? ( */}

            {orders?.orders?.filter(order => order.status === 'CONFIRMED').length === 0 ? (
              <>
                <p>No orders found</p>
              </>
            ) : (
              <>
                <Row>
                  {/* {orders?.orders?.map((obj, index) => { */}
                  {orders?.orders?.filter(order => order.status === 'CONFIRMED').map((obj, index) => {
                    return (
                      <>
                        <Col
                          xxl={4}
                          xl={4}
                          lg={4}
                          md={6}
                          sm={12}
                          xs={12}
                          className="order-collapse">
                          <Collapse onChange={console.log} expandIconPosition={'end'}>
                            <Panel
                              showArrow={true}
                              header={
                                <div className="order-card">
                                  <p className="order-info mb-0 pb-1 fw-600 color-primary ">
                                    {obj.order_number} - {getOrderStatus(obj.status)}
                                  </p>
                                  <p className="order-info mb-0 pb-1 fw-400 s-color-black">
                                    Order Date:{' '}
                                    {moment(obj.order_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                                  </p>
                                  <p className="order-info mb-0 pb-1 fw-400 s-color-black">
                                    Delivery date:{' '}
                                    {moment(
                                      obj?.delivery_details?.delivery_date,
                                      'YYYY-MM-DD'
                                    ).format('MM/DD/YYYY')}
                                  </p>
                                </div>
                              }
                              key="1">
                              {/* <div className="order-collapse-detail px-4 py-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                  nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div> */}
                            </Panel>
                          </Collapse>
                        </Col>
                      </>
                    );
                  })}

                  {orders?.pages > 1 ? (
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                      <div className="d-flex justify-content-center align-items-center">
                        {
                          orders?.currentPage > 1 ?
                            <p className="mb-0 show-more-btn mr-2" onClick={() => handleOrdersPagination(1)}>
                              show less <UpOutlined size={'small'} />
                            </p>
                            : ''
                        }
                        {orders?.currentPage <= orders.pages ? (
                          <p className="mb-0 show-more-btn ml-2" onClick={() => handleOrdersPagination(orders?.currentPage + 1)}>
                            show more <DownOutlined size={'small'} />
                          </p>
                        ) : ''}
                      </div>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </>
            )}
          </Container>
        </div>

        <Modal
          footer={false}
          centered
          title={
            <div className="d-flex justify-content-end align-items-center">
              <CloseOutlined className="cursor-pointer" onClick={() => handleCancel()} />
            </div>
          }
          closable={false}
          open={openEditMdl}
          // onCancel={() => handleCancel()}
          width={1200}>
          <Form form={form} layout="vertical" autoComplete="off">
            <Spin spinning={isFormSubmitted} tip={'processing....'}>
              <Row className="form-layout">
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the name'
                      }
                    ]}>
                    <Input size="large" placeholder="input placeholder" disabled={true} />
                  </Form.Item>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the email'
                      },
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!'
                      }
                    ]}>
                    <Input size="large" placeholder="input placeholder" disabled={true} />
                  </Form.Item>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="mobile_number"
                    label="Mobile number"
                    validateTrigger="onBlur"
                    rules={[
                      { required: true, message: 'Please input the mobile number', min: 14 }
                    ]}>
                    <Input
                      size="large"
                      placeholder="input placeholder"
                      minLength={14}
                      maxLength={14}
                      onKeyPress={(event) => {
                        validateMobileNumber(event);
                      }}
                      onChange={(e) => handlePrimary(e)}
                    />
                  </Form.Item>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="secondary_number"
                    label="Secondary phone number"
                    validateTrigger="onBlur"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            getFieldValue('secondary_number') &&
                            getFieldValue('secondary_number').length != 14
                          ) {
                            return Promise.reject('Please enter valid number'); // The validator should always return a promise on both success and error
                          } else {
                            return Promise.resolve();
                          }
                        }
                      })
                    ]}>
                    <Input
                      size="large"
                      placeholder="input placeholder"
                      minLength={14}
                      maxLength={14}
                      onKeyPress={(event) => {
                        validateMobileNumber(event);
                      }}
                      onChange={(e) => handleSecondary(e)}
                    />
                  </Form.Item>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    className=""
                    name="delivery_addr"
                    label="Delivery Address"
                    rules={[
                      {
                        required: false,
                        message: 'Please enter the delivery address'
                      }
                    ]}>
                    <GoogleAutoComplete
                      className={
                        deliveryAddressError && !deliveryAddress
                          ? 'form-control delivery-address-field-error '
                          : 'form-control'
                      }
                      apiKey={apiKey}
                      onPlaceSelected={(place) => {
                        setAddress(place);
                      }}
                      onChange={(e) => {
                        onAddresschange(e);
                      }}
                      options={{
                        types: ['address'],
                        componentRestrictions: { country: 'us' }
                      }}
                      defaultValue={deliveryAddress}
                    />
                    <p className="mb-0 ant-form-item-explain-error">
                      {zipCodeError ? zipCodeError : null}
                    </p>
                    <p className="mb-0 ant-form-item-explain-error">
                      {deliveryAddressError && !deliveryAddress ? deliveryAddressError : ''}
                    </p>
                    <p className="mb-0 text-success">
                      {!zipCodeError && deliveryAddress ? 'Services available in this area' : null}
                    </p>
                  </Form.Item>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="apt_number"
                    label="Apt. no"
                    rules={[
                      {
                        required: false
                      }
                    ]}>
                    <Input size="large" placeholder="input placeholder" />
                  </Form.Item>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="zip_code"
                    label="Zip Code"
                    rules={[
                      {
                        required: false,
                        message: 'Please enter the zipcode'
                      }
                    ]}>
                    <Input size="large" placeholder="input placeholder" />
                  </Form.Item>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <Form.Item
                    name="extra_work_id"
                    label="Do you have stairs or an elevator?"
                    rules={[
                      {
                        required: false,
                        message: 'Please select an option'
                      }
                    ]}>
                    <Select
                      size="large"
                    // onChange={onStairsSelected}
                    >
                      {extraWork?.map((obj, index) => {
                        return (
                          <Select.Option
                            key={`extarwork_${index}`}
                            value={obj?.id}>{`${obj.title} ($${obj.price})`}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}></Col>

                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="d-flex align-items-center justify-content-end">
                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      className="signup-register-btn"
                      htmlType="submit"
                      onClick={() => onSubmitCustomer()}>
                      {isFormSubmitted ? 'Processing..' : 'Save details'}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Spin>
          </Form>
        </Modal>

        <Modal
          footer={false}
          centered
          closable={false}
          open={openPytMdl}
          onOk={() => setOpenPytMdl(false)}
          onCancel={() => setOpenPytMdl(false)}
          width={450}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Row className="form-layout">
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the name'
                    }
                  ]}>
                  <Input size="large" placeholder="input placeholder" />
                </Form.Item>
              </Col>

              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the email'
                    },
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!'
                    }
                  ]}>
                  <Input size="large" placeholder="input placeholder" />
                </Form.Item>
              </Col>

              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <Form.Item
                  name="mobile_number"
                  label="Mobile number"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the mobile'
                    }
                  ]}>
                  <Input size="large" placeholder="input placeholder" />
                </Form.Item>
              </Col>

              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="d-flex align-items-center justify-content-end">
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    className="signup-register-btn"
                    htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>
    </>
  );
};

export default Account;

Account.propTypes = {
  customerData: PropTypes.any,
  getUserData: PropTypes.func,
  extraWork: PropTypes.any
};
