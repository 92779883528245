import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSession } from 'react-client-session';

import { Button, Card, Skeleton, message } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import * as _ from 'lodash';
import './style.css';

import { getSelectMovings, addtoCart } from '../../../data/API';
import { setStepCount, setMovingSuppliesProducts } from '../store/actionCreators';
import moment from 'moment';

export default function index(props) {
  const dispatch = useDispatch();
  const session = ReactSession.get('session');

  const { category } = useSelector((state) => state.package.choosePackage);
  const currentStep = useSelector((state) => state.package.currentStep);
  const { selectedProducts } = useSelector((state) => state.package.movingSupplies);
  const { selectedRentalPeriod } = useSelector((state) => state.package.choosePackage);

  const [movingSupplies, setMovingSupplies] = useState([]);

  const { isParentNextStepClicked, setParentNextStepClicked, stepRef } = props;

  const saveProductsToLocalStorage = (products) => {
    localStorage.setItem('movingSupplies', JSON.stringify(products));
  };

  const getProductsFromLocalStorage = () => {
    const savedProducts = localStorage.getItem('movingSupplies');
    if (savedProducts) {
      const products = JSON.parse(savedProducts);
      products.forEach((product) => {
        if (product.selectedQuantity > 0) {
          product.selected = true;
        } else {
          product.selected = false;
        }
      });
      return products;
    }
    return [];
  };

  const nextStep = () => {
    // if (selectedProducts?.length) {
    dispatch(setStepCount(currentStep + 1));
    // } else {
    //   message.error("Please select atleat one product...!")
    //   console.log("Select Products")
    // }
  };

  useEffect(() => {
    if (isParentNextStepClicked && stepRef == 3) {
      setParentNextStepClicked(false);
      setTimeout(
        function () {
          nextStep();
        }.bind(this),
        0
      );
    }
  }, [isParentNextStepClicked]);

  useEffect(() => {
    window.scrollTo({
      top: props.tabref.current.offsetTop - 100,
      behavior: 'smooth'
    });
  }, []);

  const prevStep = () => {
    dispatch(setStepCount(currentStep - 1));
  };

  const getMovingSupplies = async () => {
    let response = await getSelectMovings(category, 'Moving Supplies');

    if (response?.data?.results) {
      response.data.results.forEach((obj) => {
        obj.description = obj.description
          .replaceAll('</li>', ',')
          .replaceAll('&nbsp;', '')
          .replaceAll('\r\n', '')
          .replaceAll(/<\/?[^>]+(>|$)/g, '')
          .split(',');
      });

      if (selectedProducts.length > 0) {
        await syncProductsWithCart(response?.data?.results);
      } else {
        setMovingSupplies(response?.data?.results);
      }
    }
  };

  const syncProductsWithCart = async (products) => {
    selectedProducts.forEach((item) => {
      products.find((product) => {
        if (product.id === item.id) {
          product.selectedQuantity = item.quantity;
          product.quantity = item.quantity;
          product.selected = item.quantity > 0;
        }
      });
    });
    setMovingSupplies([...products]);
    saveProductsToLocalStorage(products);
  };

  const addProduct = async (index) => {
    if (movingSupplies[index].selected) {
      movingSupplies[index].quantity = movingSupplies[index].quantity + 1;
    } else {
      movingSupplies[index].selected = true;
      movingSupplies[index].quantity = 1;
    }
    let existingProdustIndex = _.findIndex(selectedProducts, { id: movingSupplies[index].id });
    if (existingProdustIndex >= 0) {
      selectedProducts[existingProdustIndex] = movingSupplies[index];
    } else {
      selectedProducts.push(movingSupplies[index]);
    }
    saveProductsToLocalStorage(movingSupplies);

    let data = {
      product: movingSupplies[index].id,
      quantity: 1,
      session: session,
      cart_main_category: 2,
      cart_sub_category: movingSupplies[index].product_sub_category,
      rental: selectedRentalPeriod.id
    };
    let res = await addtoCart(data);

    if (res?.data?.id) {
      dispatch(setMovingSuppliesProducts(selectedProducts));
      setMovingSupplies([...movingSupplies]);
    } else {
      message.error('Something went wrong while adding to cart. Please try again..!');
    }
  };

  const addToCart = async (obj) => {
    let data = {
      product: obj.id,
      quantity: obj.quantity,
      session: session,
      cart_main_category: 2,
      cart_sub_category: obj.product_sub_category,
      rental: selectedRentalPeriod.id
    };
    await addtoCart(data);
  };

  useEffect(() => {
    let data = movingSupplies;

    if (selectedProducts.length === 0) {
      data.forEach((item) => {
        item.selectedQuantity = 0;
        item.selected = false; 
      });
    } else {
      data.forEach((item) => {
        const cartProduct = selectedProducts.find((p) => p.id === item.id);
        if (cartProduct) {
          item.selectedQuantity = cartProduct.quantity;
          item.quantity = cartProduct.quantity;
          item.selected = cartProduct.quantity > 0; 
        } else {
          item.selectedQuantity = 0;
          item.selected = false; 
        }
      });
    }

    setMovingSupplies([...data]);
    saveProductsToLocalStorage(data);
  }, [selectedProducts]);


  useEffect(() => {
    const savedProducts = getProductsFromLocalStorage();
    if (savedProducts.length > 0) {
      syncProductsWithCart(savedProducts);
    } else {
      getMovingSupplies();
    }
  }, [category]);
  return (
    <>
      {/* Step 2 */}
      <div>
        <div className="steps bg-white" id="steps">
          <h2 className="shopping-cart-header text-white text-center py-2 fs-20 steps-title">
            Step 3: Moving Supplies
          </h2>
          <div className="row pl-2 pr-2">
            <div className="container px-5 my-4">
              {movingSupplies?.length == 0 ? (
                <div className="row py-4 pt-0">
                  <div className="col-md-4 p-0">
                    <Card
                      style={{ border: '1px solid #f0f0f0' }}
                      className="px-3 py-3 mx-1"
                      bordered={false}>
                      <div className="w-100 text-center py-3">
                        <Skeleton.Image active={true} />
                      </div>

                      <Skeleton className="py-3" active />

                      <Skeleton.Button
                        className="py-4"
                        active={true}
                        size={'default'}
                        shape={'default'}
                        block={true}
                      />
                    </Card>
                  </div>
                  <div className="col-md-4 p-0">
                    <Card
                      style={{ border: '1px solid #f0f0f0' }}
                      className="px-3 py-3 mx-1"
                      bordered={false}>
                      <div className="w-100 text-center py-3">
                        <Skeleton.Image active={true} />
                      </div>

                      <Skeleton className="py-3" active />

                      <Skeleton.Button
                        className="py-4"
                        active={true}
                        size={'default'}
                        shape={'default'}
                        block={true}
                      />
                    </Card>
                  </div>
                  <div className="col-md-4 p-0">
                    <Card
                      style={{ border: '1px solid #f0f0f0' }}
                      className="px-3 py-3 mx-1"
                      bordered={false}>
                      <div className="w-100 text-center py-3">
                        <Skeleton.Image active={true} />
                      </div>

                      <Skeleton className="py-3" active />

                      <Skeleton.Button
                        className="py-4"
                        active={true}
                        size={'default'}
                        shape={'default'}
                        block={true}
                      />
                    </Card>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="row py-4 pt-0">
                {movingSupplies.map((obj, index) => (
                  <div key={obj.id} className="col-md-4 p-0 pkg d-flex z-index-0">
                    <div className="card ">
                      <div className="product-header">
                        <p className="text-center mb-0  product-title color-primary fw-700">
                          {obj.title}
                        </p>
                      </div>

                      <div className="card-body">
                        <div className="product-image text-center">
                          <img src={obj.image} className="img-fluid" />
                        </div>
                        <div className="product-info text-center pl-2 pr-2">
                          <div className="mt-3">
                            <div className="text-left ml-4 mr-3">
                              {obj.description.map((desc, index) => {
                                return (
                                  <p className="mb-0 fs-14" key={index} hidden={!desc.length}>
                                    <i className="fas fa-check color-primary"></i> {desc}
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cardfooter pl-2 pr-2 mb-3">
                        <div className="product-price mb-2 mt-2 color-primary font-bold">
                          <p className="text-center mb-0 pt-2 pb-2 fs-20 fw-600">
                            {' '}
                            {/* {
                            (() => {
                              const rate = Number(selectedRentalPeriod?.period?.split(' ')[0]);
                              const rateStrings = [
                                "zero", "one", "two", "three", "four", "five", "six"
                              ];
                              const rateString = rateStrings[rate] || `${rate}`; 

                              return rate === 1 
                              ? (
                                  <>
                                  <span className="top fs-14 crs">$</span>
                                    <span className="top fs-23 crs">{obj.price * rate}</span> 
                                    <span className="middle fs-14">/ {rate} week</span>
                                  </>
                                )
                              : (
                                  <>
                                    <span className="top fs-14 crs">$</span>
                                    <span className="top fs-23 crs">{obj.price * rate}</span> 
                                    <span className="middle fs-18">/ {rate} weeks</span>
                                  </>
                                );

                            })()

                            } */}

                               ${obj.price} {obj.unit}
                            
                          </p>
                        </div>

                        {/* {obj?.stock > 0 ? (
                          <p className="mb-0 fs-14 px-3 py-2">
                            <span className="color-secondary font-bold">Available Stock : </span>
                            {obj?.stock}
                          </p>
                        ) : (
                          ''
                        )} */}
                        {obj?.stock == 0 && obj?.expected_stock_date ? (
                          <p className="mb-0 fs-14 px-3 py-2">
                            <span className="color-secondary font-bold">Available From: </span>
                            {moment(obj?.expected_stock_date).format('DD-MM-YYYY')}
                          </p>
                        ) : (
                          ''
                        )}

                        <div className="text-center mb-3">
                          {obj?.stock > 0 ? (
                            obj?.selectedQuantity ? (
                              <button
                                className="btn product-added-btn br-5 fs-15  width-90p border-0  text-center"
                                onClick={() => {
                                  addProduct(index);
                                }}>
                                <img src="img/Add-Blue.png" />
                                <button className="circle">
                                  <p className="mb-0 pb-0">{obj?.selectedQuantity}</p>
                                </button>
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger br-5 fs-15  width-90p border-0 bg-theme-secondary text-center"
                                onClick={() => {
                                  addProduct(index);
                                }}>
                                <img src="img/Add.png" />
                              </button>
                            )
                          ) : (
                            <Button type="primary" danger ghost disabled>
                              OUT OF STOCK
                            </Button>
                          )}
                        </div>
                        <p
                          hidden={obj.title !== 'Moving Dolly'}
                          className="mb-0 color-primary  text-center fs-13">
                          At least 1 moving dolly is already included in your package
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-10 p-2">
          <div className="col  d-flex justify-content-start align-items-center">
            <Button
              className="btn-previous-step"
              icon={<ArrowLeftOutlined />}
              size={'large'}
              onClick={prevStep}>
              Previous Step
            </Button>
          </div>
          <div className="col  d-flex justify-content-end align-items-center">
            <Button
              className="btn-next-step"
              icon={<ArrowRightOutlined />}
              size={'large'}
              onClick={nextStep}>
              Next Step
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
