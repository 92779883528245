import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, Form, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomer } from '../../store/actionCreators';


import { login } from '../../../../data/API';
import { setMyData, setToken } from '../../../../utils/token';


const Login = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    props.setShowHideHeader(true);
    props.setshowHideFooter(false);
    props.setshowHideinnerFooter(false);
  }, []);

  const onSubmit = async () => {
    let formValues = await form.validateFields();

    setFormSubmitted(true);

    try {
      let loginResponse = await login(formValues);

      if (loginResponse?.data?.success) {
        setToken(loginResponse?.data?.data?.token);
        setMyData({
          email: loginResponse?.data?.data?.email,
          id: loginResponse?.data?.data?.id,
          name: loginResponse?.data?.data?.name,
          agreedTerms: loginResponse?.data?.data?.agreedTerms
        });

        dispatch(setCustomer(loginResponse?.data?.data));
        message.success('Logged in successfully...!');

      if (loginResponse?.data?.data?.agreedTerms) {
        navigate('/storage-box/my-account?tab=account');
      } else {
        navigate('/storage-box/how-it-works');
      }
    } else {
      if (loginResponse?.data?.errorCode === "CLIENT_ERROR") {
        message.warning(loginResponse?.data?.message);
      } else {
        message.error('Unable to login, Please try again...!');
      }
    }
  } catch (err) {
    if (err.response && err.response.data) {
      const { errorCode, message: errorMessage } = err.response.data;
      if (errorCode === "CLIENT_ERROR" && errorMessage.includes('email is not verified')) {
        message.warning(
          {
            content: 'After confirming your email, please reset your password. Your email is not verified. Please check your inbox for a verification link.',
            duration: 30, 
          }
        );
        navigate('/storage-box/email/confirmation');
      } else {
        message.error(errorMessage || 'Unable to login, Please try again...!');
      }
    } else {
      message.error('Unable to login due to a network error. Please try again later.');
    }
  } finally {
    setFormSubmitted(false);
  }
};




  // const onSubmit = async () => {
  //   let formValues = await form.validateFields();

  //   setFormSubmitted(true);

  //   try {
  //     let loginResponse = await login(formValues);

  //     if (loginResponse?.data?.success) {
  //       setToken(loginResponse?.data?.data?.token);
  //       setMyData({
  //         email: loginResponse?.data?.data?.email,
  //         id: loginResponse?.data?.data?.id,
  //         name: loginResponse?.data?.data?.name,
  //         agreedTerms: loginResponse?.data?.data?.agreedTerms
  //       });

  //       dispatch(setCustomer(loginResponse?.data?.data));
  //       message.success('Logged in successfully...!');

  //       if (loginResponse?.data?.data?.agreedTerms) {
  //         navigate('/storage-box/my-account?tab=account');
  //       } else {
  //         navigate('/storage-box/how-it-works');
  //       }
  //       setFormSubmitted(false);
  //     } else {
  //       message.error('Unable to login, Please try again...!');
  //       setFormSubmitted(false);
  //     }
  //   } catch (err) {
  //     message.error('Unable to login, Please try again...!');
  //     setFormSubmitted(false);
  //   }
  // };

  return (
    <>
      <Container fluid={true} className="p-0 m-0">
        <div className="login-header px-3">
          <div className="login-header-content">
            <p className="header">Login</p>
          </div>
        </div>
      </Container>

      <Container fluid={true} className="signup-form-bg py-5">
        <Row className="align-items-center justify-content-center">
          <Col xxl={5} xl={5} lg={5} md={6} sm={12} xs={12}>
            <div className="signup-form px-2">
              <Form form={form} layout="vertical" autoComplete="off">
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className={'pb-0'}>
                    <Form.Item
                      name="email"
                      label="Email"
                      validateTrigger="onBlur"
                      rules={[
                        { required: true, message: 'Please input the email address' },
                        { type: 'email', message: 'Please enter a valid email address' }
                      ]}
                      validateStatus={showValidation ? 'success' : ''}>
                      <Input
                        size={'large'}
                        type="text"
                        placeholder=""
                        className="signup-form-input"
                        onChange={() => {
                          setShowValidation(true);
                          let el = document.getElementById('email_address_help');
                          if (el) {
                            el.classList.add('d-none');
                          }
                        }}
                        onBlur={() => {
                          setShowValidation(false);
                          let el = document.getElementById('email_address_help');
                          if (el) {
                            el.classList.remove('d-none');
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className={'pb-0'}>
                    <div className="position-relative">
                      <Form.Item
                        className="mb-0"
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your password!'
                          }
                        ]}>
                        <Input
                          size={'large'}
                          type={!showPassword ? 'password' : 'text'}
                          placeholder="******"
                          className={`signup-form-input bluebox-placeholder ${showPassword ? "mb-3" : ""}`}
                        />
                      </Form.Item>
                      <div className="toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <EyeInvisibleOutlined className="ant-eye-icon" />
                        ) : (
                          <EyeOutlined className="ant-eye-icon" />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="text-right pb-0">
                    <p className="forgot-password mb-4 mt-0">
                      Forgot you password?{' '}
                      <span
                        className="navigate-link "
                        onClick={() => navigate('/storage-box/reset-password/generate')}>
                        Click here to reset it
                      </span>
                    </p>

                    <Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="signup-register-btn"
                        onClick={onSubmit}
                        loading={isFormSubmitted}>
                        Login
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
        <div className="signup-form-divider">
          <div className="divider"></div>
        </div>
        <div className="signup-footer pt-3">
          <p className="mb-0 sub-text">
            Don&#39;t have an account?{' '}
            <span className="navigate-link " onClick={() => navigate('/storage-box/register')}>
              Signup Here
            </span>
          </p>
        </div>
      </Container>
    </>
  );
};

export default Login;

Login.propTypes = {
  setShowHideHeader: PropTypes.func,
  setshowHideFooter: PropTypes.func,
  setshowHideinnerFooter: PropTypes.func
};
