import { ArrowLeftOutlined, ArrowRightOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Select,
  Spin,
  Modal
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import { setStepCount, setPersonalDetails, setStorageStepCount } from '../../store/actionCreators';
import { createCustomer, getHdyfu, updateCustomer } from '../../../../data/API';
import { storageStripeCheckoutSession, placeStorageOrder } from '../../../../data/API';
import { getMyData } from '../../../../utils/token';
import useGetPrice from '../../../../hooks/useGetPrice';
import TermsServiceModal from './TermsService /index';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PAYMENT_KEY);

const PersonalDetails = ({
  tabref,
  isParentNextStepClicked,
  setParentNextStepClicked,
  stepRef,
  extraWork,
  customerInfo
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isForFuture, setIsForFuture] = useState(false);
  const { order, customerr } = useSelector((state) => state.storage);
  const [showValidation, setShowValidation] = useState(false);
  const { boxPrices } = useGetPrice();
  const currentStep = useSelector((state) => state.storage.currentStorageStep);
  const { personalDetails } = useSelector((state) => state.storage.order);
  const userInfo = getMyData();
  const customer = JSON.parse(localStorage.getItem('authData'));
  const setOrder = (payload) => dispatch({ type: 'SET_ORDER', payload });
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const userIsLoggedIn = !!userInfo;
  const [hearingSources, setHearingSources] = useState([]);
  const [isDeliveryDetailsLoaded, setIsDeliveryDetailsLoaded] = useState(false);
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [hasAgreedInModal, setHasAgreedInModal] = useState(false);

  useEffect(() => {
    if (userInfo && Object.keys(personalDetails).length == 0) {
      form.setFieldsValue({
        name: userInfo?.name,
        email: userInfo?.email
      });
    }
  }, []);

 useEffect ( () => {
  getHearingSources();
 }, [])

 const getHearingSources = async () => {
     const response = await getHdyfu();
 
     if (response?.data?.results) {
       setHearingSources(response?.data?.results);
     } else {
       return message.error('error while fetching hearing sources');
     }
   };

 useEffect(() => {
  const storedState = localStorage.getItem('storageState');
  if (storedState) {
    const parsedState = JSON.parse(storedState);
    
    if (parsedState.order?.personalDetails) {
      const personalData = parsedState.order.personalDetails;

      form.setFieldsValue({
        name: personalData.name,
        email: personalData.email,
        mobile: personalData.mobile,
        secondary_mobile: personalData.secondary_mobile
      });

      setPersonalDetails(personalData.name, personalData.email, personalData.mobile, personalData.secondary_mobile);
      dispatch(setPersonalDetails(personalData));
    }
  }
}, []);

// Save state to localStorage whenever relevant state changes
useEffect(() => {
  if (order) {
    const stateToStore = {
      currentStep,
      order: {
        boxCount: order.boxCount,
        deliveryDetails: order.deliveryDetails || {},
        paymentDetails: order.paymentDetails || {},
        personalDetails: order.personalDetails || {}
      }
    };
    localStorage.setItem('storageState', JSON.stringify(stateToStore));
  }
}, [currentStep, order]);

useEffect(() => {
  if (userInfo && (!personalDetails || Object.keys(personalDetails).length === 0)) {
    const initialValues = {
      name: userInfo?.name,
      email: userInfo?.email
    };
    form.setFieldsValue(initialValues);
    dispatch(setPersonalDetails(initialValues));
  }
}, [userInfo]);

useEffect(() => {
  // Check if we're returning from a successful payment
  const urlParams = new URLSearchParams(window.location.search);
  const paymentStatus = urlParams.get('payment_status');
  
  if (paymentStatus === 'success') {
    // Clear storage state
    localStorage.removeItem('storageState');
  }
}, []);

useEffect(() => {
  if (customerInfo?.custormerData) {
    const phoneNumbers = {
      mobile: formatPhoneNumber(customerInfo.custormerData.mobile),
      secondary_mobile: formatPhoneNumber(customerInfo.custormerData.secondary_mobile)
    };
    form.setFieldsValue(phoneNumbers);
    dispatch(setPersonalDetails({ ...personalDetails, ...phoneNumbers }));
  }
}, [customerInfo]);


// Add check for customer info if logged in
  useEffect(() => {
    if (customerInfo) {
      form.setFieldsValue({
        mobile: formatPhoneNumber(customerInfo?.custormerData?.mobile),
        secondary_mobile: formatPhoneNumber(customerInfo?.custormerData?.secondary_mobile)
      });
    }
  }, [customerInfo]);



  useEffect(() => {
    window.scrollTo({
      top: tabref?.current.offsetTop - 100,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    if (personalDetails) {
      form.setFieldsValue({
        name: userInfo ? userInfo?.name : personalDetails?.name ? personalDetails?.name : '',
        email: userInfo ? userInfo?.email : personalDetails?.email ? personalDetails?.email : '',
        mobile: personalDetails?.mobile ? formatPhoneNumber(personalDetails?.mobile) : '',
        secondary_mobile: personalDetails?.secondary_mobile
          ? formatPhoneNumber(personalDetails?.secondary_mobile)
          : '',
        coupon: personalDetails?.coupon ? personalDetails?.coupon : '',
        isForFuture: personalDetails?.isForFuture ? personalDetails?.isForFuture : false
      });
    }
  }, [personalDetails]);

  useEffect(() => {
    if (customerInfo) {
      form.setFieldsValue({
        mobile: formatPhoneNumber(customerInfo?.custormerData?.mobile),
        secondary_mobile: formatPhoneNumber(customerInfo?.custormerData?.secondary_mobile)
      });
    }
  }, [customerInfo]);

  const nextStep = () => {
    dispatch(setStorageStepCount(currentStep + 1));
  };

  const prevStep = () => {
    dispatch(setStorageStepCount(currentStep - 1));
  };

  useEffect(() => {
    if (isParentNextStepClicked && stepRef == 3) {
      setParentNextStepClicked(false);
      setTimeout(
        function () {
          onSubmit();
        }.bind(this),
        0
      );
    }
  }, [isParentNextStepClicked]);

  const onSubmit = async () => {
    try {

      if (!order.deliveryDetails || Object.keys(order.deliveryDetails).length === 0) {
        message.error('Delivery details are missing. Please go back and complete Step 2.');
        setIsFormSubmitted(false);
        return;
      }
      
       let formValues = await form.validateFields();

       if (formValues?.mobile) {
         formValues.mobile = formValues.mobile.replace(/[()-\s]/g, '');
       }
       if (formValues?.secondary_mobile) {
         formValues.secondary_mobile = formValues.secondary_mobile.replace(/[()-\s]/g, '');
       }
       setIsFormSubmitted(true);
       
       if (!userIsLoggedIn) {
         if (!agreeTerms) {
           message.error('Please agree to BlueBox Terms of Service');
           setIsFormSubmitted(false);
           return;
         }

         const customerData = {
           name: formValues.name,
           email: formValues.email,
           mobile: formValues.mobile,
           secondary_mobile: formValues.secondary_mobile,
           password: formValues.password,
           hdyfu_id: formValues.hdyfu_id,
           agreedTerms: true 
         };
         const customerResponse = await createCustomer(customerData);
         setIsFormSubmitted(false);
         if (!customerResponse?.data?.success) {
           throw new Error('Failed to create customer');
         }
       }
       
       const updatedOrder = {
         ...order,
         personalDetails: formValues
       };
       dispatch(setOrder(updatedOrder));
       
       const orderObj = {
         boxCount: order.boxCount,
         customer_id: userInfo ? userInfo.id : undefined, 
         deliveryDetails: order.deliveryDetails || {},
         personalDetails: formValues,
         email: formValues.email, 
         name: formValues.name, 
         mobile: formValues.mobile, 
         secondary_mobile: formValues.secondary_mobile,
         total_amount: calculateTotalAmount(),
         boxes_amount: order.boxCount * Number(boxPrices[0]?.price) * 3,
         order_date: new Date(),
         password: formValues.password,
         cnfPassword: formValues.cnfPassword
       };
       

       const completeState = {
         currentStep,
         order: updatedOrder
       };
       localStorage.setItem('storageState', JSON.stringify(completeState));
       
       let orderResponse = await placeStorageOrder(orderObj);
       
       if (orderResponse?.data?.data?.id) {
         let sessionPayload = {
           order_id: orderResponse.data.data.id,
           amount: orderObj.total_amount,
           discount: 0,
           payment: {
             billing_details: {
               email: formValues.email
             }
           }
         };
         
         let sessionRes = await storageStripeCheckoutSession(sessionPayload);
         if (sessionRes?.data?.success) {
           const stripe = await stripePromise;
           const result = await stripe.redirectToCheckout({
             sessionId: sessionRes.data.data.session
           });
           setIsFormSubmitted(false);
         } else {
           message.error('Unable to place order, Please try again...!');
           setIsFormSubmitted(false);
         }
       } else {
         message.error('Unable to place order, Please try again...!');
         setIsFormSubmitted(false);
       }
     } catch (error) {
       message.error(error.message || 'Unable to process order. Please try again.');
       setIsFormSubmitted(false);
     }
   };
  
 
   const changeAgreeTerms = (e) => {
    if (!hasAgreedInModal) {
      e.preventDefault();
      setIsTermsModalVisible(true);
      return;
    }
    
    setAgreeTerms(e.target.checked);
    form.setFieldsValue({
      termsAndCondition: e.target.checked
    });
  };

  const handleTermsAgreement = (agreed) => {
    setHasAgreedInModal(agreed);
    setAgreeTerms(agreed);
    form.setFieldsValue({
      termsAndCondition: agreed
    });
  };

  const showTermsModal = () => {
    setIsTermsModalVisible(true);
  };

  const handleTermsModalClose = () => {
    setIsTermsModalVisible(false);
  };

  // Handle terms acceptance from modal
  const handleTermsAccept = (accepted) => {
    if (accepted) {
      setHasAgreedInModal(true);
      setAgreeTerms(true);
      form.setFieldsValue({
        termsAndCondition: true
      });
    }
  };

  const calculateTotalAmount = () => {
    let extraCost = 0;
    if (order?.deliveryDetails?.extra_work_id) {
      const extraWorkObj = extraWork?.find(work => work.id === order.deliveryDetails.extra_work_id);
      if (extraWorkObj) {
        extraCost = Number(extraWorkObj.price);
      }
    }
    
    // Default to a base price if boxPrices[0] is undefined
    const basePrice = boxPrices[0]?.price || 0;
    return order.boxCount * Number(basePrice) * 3 + extraCost;
  };

  const validateMobileNumber = (e) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNum = value.replace(/[^\d]/g, '');
    const phoneLen = phoneNum.length;

    if (phoneLen < 4) return phoneNum;

    if (phoneLen < 7) {
      return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;
    }

    return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(6, 10)}`;
  };

  const handlePrimary = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    form.setFieldsValue({
      mobile: formattedPhone
    });
    handlePersonalDetailsRedux('mobile', formattedPhone);
  };

  const handleSecondary = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    form.setFieldsValue({
      secondary_mobile: formattedPhone
    });
    handlePersonalDetailsRedux('secondary_mobile', formattedPhone);
  };

  const handlePersonalDetailsRedux = (type, e) => {
    switch (type) {
      case 'name':
        form.setFieldsValue({
          name: e.target.value
        });
        break;
      case 'email':
        form.setFieldsValue({
          email: e.target.value
        });
        break;
      case 'mobile':
        form.setFieldsValue({
          mobile: e
        });
        break;
      case 'secondary_mobile':
        form.setFieldsValue({
          secondary_mobile: e
        });
        break;
      case 'coupon':
        form.setFieldsValue({
          coupon: e.target.value
        });
        break;
      case 'isForFuture':
        form.setFieldsValue({
          isForFuture: e.target.checked
        });
        break;
      case 'password':
        form.setFieldsValue({
          password: e.target.value
        });
        break;
      case 'cnfPassword':
        form.setFieldsValue({
          cnfPassword: e.target.value
        });
        break;
    }

    dispatch(setPersonalDetails(form.getFieldsValue()));
  };

  return (
    <div>
      <div className="steps bg-white" id="steps">
        <h2 className="shopping-cart-header text-white text-center py-2 fs-20 steps-title">
          Step 3: Personal Details
        </h2>
        <Card bordered={false} className="w-100 px-3 pt-3 pb-4">
          <Spin spinning={isFormSubmitted} tip="processing...">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              initialValues={{
                isForFuture: personalDetails?.isForFuture ? personalDetails?.isForFuture : false
              }}
              style={{ position: 'relative', zIndex: 0 }}>
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Please enter the name' }]}>
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                      onChange={(e) => handlePersonalDetailsRedux('name', e)}
                    />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="email"
                    label="Email"
                    validateTrigger="onBlur"
                    rules={[
                      { required: true, message: 'Please input the email address' },
                      { type: 'email', message: 'Please enter a valid email address' }
                    ]}
                    validateStatus={showValidation ? 'success' : ''}>
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                      onChange={(e) => {
                        setShowValidation(true);
                        let el = document.getElementById('email_help');
                        if (el) {
                          el.classList.add('d-none');
                        }
                        handlePersonalDetailsRedux('email', e);
                      }}
                      onBlur={() => {
                        setShowValidation(false);
                        let el = document.getElementById('email_help');
                        if (el) {
                          el.classList.remove('d-none');
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {!userIsLoggedIn && (
                <Row>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className={'pb-0'}>
                    <div className="position-relative">
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your password!'
                          }
                        ]}>
                        <Input
                          size={'large'}
                          type={!showPassword ? 'password' : 'text'}
                          className="signup-form-input bluebox-placeholder"
                        />
                      </Form.Item>
                      <div className="toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <EyeInvisibleOutlined className="ant-eye-icon" />
                        ) : (
                          <EyeOutlined className="ant-eye-icon" />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className={'pb-0'}>
                    <div className="position-relative">
                      <Form.Item
                        label="Confirm Password"
                        name="cnf_password"
                        dependencies={['password']}
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!'
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('The two passwords that you entered do not match!')
                              );
                            }
                          })
                        ]}>
                        <Input
                          size={'large'}
                          type={!showCnfPassword ? 'password' : 'text'}
                          className="signup-form-input bluebox-placeholder mb-0"
                        />
                      </Form.Item>
                      <div
                        className="toggle-icon"
                        onClick={() => setShowCnfPassword(!showCnfPassword)}>
                        {showCnfPassword ? (
                          <EyeInvisibleOutlined className="ant-eye-icon" />
                        ) : (
                          <EyeOutlined className="ant-eye-icon" />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              
              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="mobile"
                    label="Mobile Number"
                    validateTrigger="onBlur"
                    rules={[
                      { required: true, message: 'Please input the mobile number', min: 14 }
                    ]}>
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                      minLength={14}
                      maxLength={14}
                      onKeyPress={(event) => {
                        validateMobileNumber(event);
                      }}
                      onChange={(e) => {
                        handlePrimary(e);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="secondary_mobile"
                    label="Secondary phone number"
                    validateTrigger="onBlur"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            getFieldValue('secondary_phone_number') &&
                            getFieldValue('secondary_phone_number').length != 14
                          ) {
                            return Promise.reject('Please enter valid number');
                          } else {
                            return Promise.resolve();
                          }
                        }
                      })
                    ]}>
                    <Input
                      size={'large'}
                      type="text"
                      placeholder=""
                      className="signup-form-input"
                      minLength={14}
                      maxLength={14}
                      onKeyPress={(event) => {
                        validateMobileNumber(event);
                      }}
                      onChange={(e) => {
                        handleSecondary(e);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}></Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item name="isForFuture" noStyle valuePropName="checked">
                    <Checkbox
                      className="agree-lable"
                      onChange={(e) => {
                        handlePersonalDetailsRedux('isForFuture', e);
                      }}>
                      Save my details for future orders
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

            {!userIsLoggedIn && (
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className={'pb-0 mt-m5'}>
                  <Form.Item
                    name="hdyfu_id"
                    label="How did you hear about us?"
                    rules={[{ required: true, message: 'Please input how you hear about us?' }]}>
                    <Select size="large">
                      {hearingSources?.map((obj, index) => {
                        return (
                          <Select.Option key={`source_${index}`} value={obj?.id}>
                            {obj.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  className="px-3 py-0 text-start"
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <Form.Item
                    className="mb-0"
                    name="termsAndCondition"
                    valuePropName="checked"
                    rules={[
                      { required: true, message: 'Please accept the terms and condition' }
                    ]}
                  >
                    <Checkbox
                      className="fs-15"
                      value={agreeTerms}
                      onChange={(e) => {
                        changeAgreeTerms(e);
                      }}
                    >
                      I agree to <span 
                        onClick={showTermsModal} 
                        className="agree-term" 
                        style={{ cursor: 'pointer', color: '#1890ff', textDecoration: 'underline' }}
                      >
                        Terms and Services
                      </span>
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            )}
            </Form>
          </Spin>
        </Card>
      </div>

      <div className="row mt-4 mb-10">
        <div className="col d-flex justify-content-start align-items-center">
          <Button
            className="btn-previous-step"
            icon={<ArrowLeftOutlined />}
            onClick={prevStep}
            size={'large'}>
            Previous Step
          </Button>
        </div>
        <div className="col d-flex justify-content-end align-items-center">
          <Button
            style={{ maxWidth: '250px' }}
            className="btn-next-step"
            icon={<ArrowRightOutlined />}
            onClick={onSubmit}
            loading={isFormSubmitted}
            size={'large'}>
            {!isFormSubmitted ? 'Continue to payment' : 'Redirecting..'}
          </Button>
        </div>
      </div>

      {/* Terms Service Modal */}
      <TermsServiceModal 
      visible={isTermsModalVisible} 
      onClose={handleTermsModalClose} 
      onTermsAccept={handleTermsAccept}
    />
    </div>
  );
};


export default PersonalDetails;

PersonalDetails.propTypes = {
  tabref: PropTypes.any,
  stepRef: PropTypes.any,
  isParentNextStepClicked: PropTypes.any,
  setParentNextStepClicked: PropTypes.any,
  deliveryWindows: PropTypes.any,
  extraWork: PropTypes.any,
  customerInfo: PropTypes.any
};