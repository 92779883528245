import React, { useEffect, useState, useRef, Suspense } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useNavigate } from 'react-router-dom';
import ZipCode from '../../ZipModal';
import PropTypes from 'prop-types';
import RenderIf from '../../../../Utility/RenderIf';
import { resetState } from '../../../../store/action';
import { useDispatch } from 'react-redux';
import { clearCart, getDiscount, validateStorageZipCode } from '../../../../data/API';
import { ReactSession } from 'react-client-session';
import { Helmet } from 'react-helmet';
import Offers from '../../../Offers';

import {
  getHomeData,
  getTestimonials,
  getContactInfo,
  getWhyUs,
  getLocations,
  checkZipCode,
  saveQuote
} from '../../../../data/API';

import toast from 'react-hot-toast';
import './index.css';

import SimpleSlider from '../../slider';
import { Col, Container, Row } from 'react-bootstrap';
import FilledBox from '../../../../assets/images/filled_box.png';
import Storage from '../../../../assets/images/storage.png';
import { Button, Form, Image, Input, message, Select } from 'antd';
import uuid from 'react-uuid';
import { setPincode } from '../../../StorageBox/store/actionCreators';
import { getToken } from '../../../../utils/token';
import { BgColorsOutlined } from '@ant-design/icons';
const LazyImage = React.lazy(() => import('../../../../Utility/LazyImage'));

export default function Fremont_landing_page(props) {
  const { Option } = Select;

  const [mainloader, setMainloader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMsg, setMsg] = useState('');
  const [isError, setError] = useState(false);
  const navigate = useNavigate();
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator());

  const [home, setHomeData] = useState();
  const [testimonials, setTestimonials] = useState([]);
  const [why_us, setWhyUs] = useState([]);
  const [contact_info, setContactInfo] = useState([]);
  const [locations, setLocation] = useState([]);
  const [zipcode, setZipcode] = useState([]);
  const [popupData, setPopupData] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [form] = Form.useForm();
  const loginToken = getToken();

  const onFinish = (val) => {
    message.success('Submit success!');
  };
  const onFinishFailed = (err) => {
    message.error('Something Went Wrong Try Again!');
  };
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    full_name: '',
    email: '',
    phone: '',
    address: '',
    delivery_date: '',
    pickup_zipcode: '',
    delivery_zipcode: ''
  });

  const handleLearnMore = () => {
    navigate('/storage-boxes');
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    const item = {
      value: true,
      expiry: new Date().getTime()
    };
    // console.log(item.expiry);
    localStorage.setItem('zipcode', JSON.stringify(item));
  };
  const handleClosePopup = () => {
    setPopupVisible(false);
  };
  const loadiframe = () => {
    window.$('#myModal').modal('show');
    document.getElementById('iframediv').innerHTML =
      " <button type='button' className='close' onClick= window.$('#myModal').modal('hide') > <span aria-hidden='true'>&times;</span> </button>  <iframe src='https://www.google.com/maps/d/embed?mid=1tKoG-65lKUliYMUheHqFHds9xbLieQQ' width='640' height='480'></iframe>";
  };

  const handleQuoteSubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      saveQuote(values)
        .then((res) => {
          toast.success('Successfully created!');
          ('Message sent successfully');
        })
        .catch((e) => {
          toast.error('Some error occure,please try again');
        });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleZipCode = async (values) => {
    if (values?.service_type && values?.service_type == 'STORAGE_ON_DEMAND') {
      if (validator.current.allValid()) {
        setSubmitLoader(true);

        let payload = {
          deliveryZipCode: values.delivery_zipcode
        };

        let validateRes = await validateStorageZipCode(payload);

        if (validateRes?.data?.data?.serviceExist) {
          dispatch(setPincode(values.delivery_zipcode));
          setSubmitLoader(false);

          if (loginToken) {
            navigate('/storage-box/buying-box');
          } else {
            navigate('/storage-box/login');
          }
        } else {
          setSubmitLoader(false);
          message.error('Service not available in this area.');
        }
      } else {
        validator.current.showMessages();
        forceUpdate(1);
        return false;
      }
    } else {
      setMainloader(true);
      if (validator.current.allValid()) {
        setSubmitLoader(true);

        let obj = {
          pickup_zipcode: values.pickup_zipcode,
          delivery_zipcode: values.delivery_zipcode
        };
        checkZipCode(obj)
          .then((res) => {
            if (res?.data?.success) {
              setSubmitLoader(false);
              navigate('/packages');
            } else {
              setSubmitLoader(false);
              message.error(res?.data?.message);
            }
          })
          .catch((e) => {
            setSubmitLoader(false);
            console.log(e);
          });
      } else {
        validator.current.showMessages();
        forceUpdate(1);
        return false;
      }
    }
  };

  useEffect(() => {
    clearCart(ReactSession.get('session'));
    dispatch(resetState());
    ReactSession.set('session', uuid());
    var cancel = false;
    props.setshowZipModal(true);

    getHomeData()
      .then((res) => {
        if (cancel) return;
        setHomeData(res.data);
      })
      .catch((e) => console.log(e));

    getWhyUs()
      .then((res) => {
        if (cancel) return;
        setWhyUs(res.data);
      })
      .catch((e) => console.log(e));

    getTestimonials()
      .then((res) => {
        if (cancel) return;
        setTestimonials(res.data);
      })
      .catch((e) => console.log(e));

    getLocations()
      .then((res) => {
        if (cancel) return;
        setLocation(res.data);
      })
      .catch((e) => console.log(e));

    if (zipcode === '') {
      setZipcode(false);
    }

    return () => {
      cancel = true;
    };
  }, []);

  const [showM, setModal] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const hideZModal = () => {
    setModal(false);
  };

  const showZModal = () => {
    setModal(true);
  };
  function toggleModalFunction(argu) {
    setToggleModal(argu);
  }

  const fetchOffer = async () => {
    let res = await getDiscount();
    setPopupData(res?.data);
    if (res?.data[0].display_location == 'both' || res?.data[0].display_location == 'home_page') {
      setPopupVisible(true);
    }
  };
  useEffect(() => {
    fetchOffer();
  }, []);

  return (
    <>
      <Helmet>
        <title>BlueBox: Moving Box Rentals & Storage in Fremont Area</title>
        <meta
          name="description"
          content="BlueBox provides a greener, cheaper and more convenient moving box rentals and storage on-demand services in the Fremont Area"
        />
        <meta
          name="keywords"
          content="reusable moving box rentals, storage San Francisco, moving box rentals San Francisco, moving box rentals San Jose, moving box rentals Oakland"
        />
      </Helmet>
      <ZipCode
        showModal={toggleModal}
        hideModal={hideZModal}
        zipcode={zipcode}
        setZipcode={setZipcode}
        toggleModal={toggleModal}
        toggleModalFunction={toggleModalFunction}
        storeStatus={props.storeStatus}
        storeStatusMessage={props.storeStatusMessage}
      />
      {/* <!-- hero --> */}
      <Offers isVisible={isPopupVisible} onClose={handleClosePopup} popupData={popupData} />
      <section className="hero position-relative fremont">
        <div className="container">
          <div className="row">
            <div className="col-md-12 position-relative home-banner"></div>
          </div>
        </div>
      </section>

      <section className="mb-6 text-center city-name moving-section">
        <div className="container">
          <div className="row-city">
            <div className="moving-inner">
              <h2>Moving boxes Fremont</h2>
              <p className="text-center land-sub-head clr-black">
                Instead of buying expensive cardboard boxes for your Fremont move, save money by
                renting reusable plastic moving boxes from BlueBox with free delivery and pickup. We
                also offer next day delivery.
              </p>
              <p className="text-center land-sub-head clr-black">
                You can place an order in just minutes below or by calling <span>650-502-9522</span>
                .
              </p>

              <div className="order-boxes-btn">
                <div className="border-vactor"></div>
                <button
                  className="btn btn-primary w-100 p-2 d-block aos-init aos-animate"
                  // data-toggle="modal"
                  // data-target="#zipModal"
                  onClick={() => {
                    showZModal();
                    setToggleModal(true);
                  }}>
                  Order boxes
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- hero --> */}
      {/* <!-- Modal --> */}
      <ZipCode showModal={showM} hideModal={hideZModal} />

      {/* About Us */}

      <section className="smallstartup-section">
        <div className="container">
          {/* <p className="text-center about-us-heading fw-600 d-center about-title underline">
          About Us
        </p> */}
          <div className="row">
            <div className="col-12 col-lg-6  d-flex justify-content-end smallstartup_img">
              <Suspense fallback={<div>Loading...</div>}>
                <LazyImage
                  src="img/AboutUs-section.png"
                  alt="About"
                  className=""
                  width="530"
                  height="430"
                />
              </Suspense>
              {/* <Suspense fallback={<div>Loading...</div>}>
              <LazyImage
                className="about-img-sm"
                src="img/AboutUs-section-sm.png"
                alt="About"
                width="340"
                height="270"
              />
            </Suspense> */}
            </div>
            <div className="col-12 col-lg-6   d-flex flex-column justify-content-center about-text">
              <p>
                You have more important things to do when you have an upcoming move on the calendar
                than wasting time (and money) visiting the big box chains just to pay too much for
                something you’ll use only once.
              </p>

              <p>
                To make matters worse, you’ll have to spend hours building boxes fussing with sticky
                and messy tape.
              </p>

              <p className="Instead-text">
                Instead, rent moving boxes from <span>BlueBox</span> and we’ll drop them off right
                at your front door in Fremont.
              </p>
              <Button className="about-us-btn d-none" onClick={() => navigate('/about')} block>
                More about us
              </Button>
            </div>
          </div>
          
        </div>
      </section>

      {/* About Us End*/}

      {/* <!-- why choos us --> */}

      <section className="ourBoxes">
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-md-12  text-center">
              <h1 className="d-center underline">
                Why Choose BlueBox?
              </h1>
            </div> */}
            <div className="ourBoxes_inner">
              <div className="row">
                <div className="col-md-6">
                  <div className=" ourBoxes_cardBox">
                    <div className="ourBoxes_cardBox_img">
                      <img src="img/Sanitized-circle.png" alt="" width="100" height="100" />
                    </div>
                    <div className="ourBoxes_cardBox_content">
                      <h4>Hand-cleaned & Sanitized</h4>
                      <p className="sub-description">
                        Our boxes are hand-cleaned and sanitized between use, so you never have to
                        worry about bed bugs or other mites or insects that love to burrow and make
                        their home inside cardboard boxes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ourBoxes_cardBox">
                    <div className="ourBoxes_cardBox_img">
                      <img src="img/perfect-size-circle.png" alt="" width="100" height="100" />
                    </div>
                    <div className="ourBoxes_cardBox_content">
                      <h4>One perfect standard box size</h4>
                      <p className="sub-description">
                        It can’t get any easier to pack up your belongings. We have one standard box
                        size perfect for moving any and all items. The size of our boxes ensures
                        they are never too big to get too heavy to lift and they also cube out
                        perfectly in moving vans and trucks.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="ourBoxes_cardBox bigbox" style={{ gap: '0px' }}>
                    <div className="border-vactor"></div>
                    <div className="ourBoxes_cardBox_img">
                      <img
                        style={{ height: '200px', width: '200px' }}
                        src="img/bigBlueBox.png"
                        alt=""
                        width="100"
                        height="100"
                      />
                    </div>
                    <div className="ourBoxes_cardBox_content_bigbox">
                      <h4>Need a really big box?</h4>
                      <p className="sub-description">
                        You can rent our BigBlueBox. They’re huge! Use this box for linens, duvets,
                        blankets and electronics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- why choos us --> */}
      {/* <!-- additional moving --> */}

      <section className="additionalmoving-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="additionalmoving-inner">
                <p>
                  Our boxes will arrive assembled with handles and attached/interlocking lids so
                  they are ready to be packed and they never need tape.
                </p>
                <p className="mt-2">
                  {' '}
                  We also carry additional moving and packing supplies to ensure Fremont customers
                  enjoy a sustainable, stress-free move!
                </p>
              </div>
            </div>
          </div>
          <div className="order-boxes-btn">
            <button
              className="btn btn-primary w-100 p-2 d-block aos-init aos-animate"
              onClick={() => {
                showZModal();
                setToggleModal(true);
              }}>
              Order boxes
            </button>
          </div>
        </div>
      </section>

      {/* <!-- additional moving --> */}
      {/* <!-- whyChoose-section  --> */}

      <section className="whyChoose-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="whyChoose-inner">
                <h2>Why choose BlueBox?</h2>
                <div className="whyChoose-table">
                  <table className="w-100">
                    <thead>
                      <tr
                        style={{ alignItems: 'center', justifyContent: 'center' }}
                        className="text-center">
                        <th></th>
                        <th style={{ color: 'white' }}>
                          <img
                            src="img/Color-black-bg@2x.png"
                            style={{ width: '64px', height: '32px', top: '85px', left: '388px' }}
                          />
                        </th>
                        <th style={{ color: 'white' }}>
                          <img
                            src="img/card-board.png"
                            style={{ width: '55px', height: '59px', top: '71px', left: '474px' }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>Free delivery and pick up</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Easy online ordering</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Fully assembled</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>No tape needed</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Ready to pack</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>No breakage, holes, tears</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Water resistant</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Crushproof</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Clean and sanitized</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p>Convenient</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p>Cheaper</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <p>Eco- Friendly</p>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-check.svg" />
                          </div>
                        </td>
                        <td>
                          <div className='text-center w-20"'>
                            <img src="svg/circular-close.svg" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img className="w-100" src="img/new-boxes-mobile1.png" alt="#" />
            </div>
            <div className="col-12 col-md-6">
              <img className="w-100" src="img/new-boxes-mobile2.png" alt="#" />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- whyChoose-section --> */}

      {/* <div className="container-fluid " style={{ background: 'white', paddingTop: '50px' }}>
        <div className="row section-ml mt-4">
          <div className="col-md-6 col-sm-12 d-flex justify-content-end about-image">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage
                className="app-section-img-lg"
                src="img/App-section.png"
                alt="App"
                width="505"
                height="545"
              />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage
                className="app-section-img-sm"
                src="img/App-section-sm.png"
                alt="App"
                width="335"
                height="360"
              />
            </Suspense>
          </div>
          <div className="col-md-6 col-sm-12 d-flex flex-column justify-content-center fs-23 about-text ">
            <p className="about-us-heading fw-600 d-center underline">
              Download our app
            </p>
            <p className="mt-3 text-left">
              Stress-free moving and storage solutions in the palm of your hand
            </p>
            <div className="row mt-3">
              <a href="#" className="col-md-3  app-download-button">
                <img src="img/GooglePlay.png" alt="App" width="100%" height="100%" />
              </a>
              <a
                href="https://apps.apple.com/us/app/bluebox-rent/id6450204692"
                className="col-md-3  app-download-button">
                <img src="img/AppStore.png" alt="App" width="100%" height="100%" />
              </a>
            </div>
          </div>
        </div>
        <div className="row section-xs " style={{ marginTop: '10px', paddingBottom: '30px' }}>
          <p className="about-us-heading fw-600 d-center underline">
            Download our app
          </p>
          <p className="mt-3 text-center">
            Stress-free moving and storage solutions in the palm of your hand
          </p>

          <div className="col-12  d-flex justify-content-end about-image">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage className="app-section-img-lg" src="img/App-section.png" alt="" />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage className="app-section-img-sm" src="img/App-section-sm.png" alt="" />
            </Suspense>
          </div>
          <div className="col-12  d-flex flex-column justify-content-center fs-23 about-text">
            <div className="row mt-3">
              <a href="" className="col-md-6 col-sm-12 app-download-button">
                <img src="img/GooglePlay.png" alt="" />
              </a>
              <a
                href="https://apps.apple.com/us/app/bluebox-rent/id6450204692"
                className="col-md-6 col-sm-12 app-download-button app-store-btn">
                <img src="img/AppStore.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div> */}

      {/* <!-- beats cardboard box --> */}
      {/* <section className="cardboardBox">
        <div className="container-fluid">
          <div className="cardbox">
            <div className="row">
              <div className="col-md-12">
                <img src="img/new-boxes-desktop1.png" alt="" />
              </div>
              <div id="mydiv">
                <img src="img/new-boxes-mobile1.png" className="image_mobile" alt="" />
                <img src="img/new-boxes-mobile2.png" className="image_mobile" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- beats cardboard box --> */}

      {/* <!-- slider Section --> */}
      <section className="sliderSection py-3 position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="sliderSection-heading">
                <h5 className="sliderSection-title">{`Don't take it from us...`}</h5>
                <h2 className="sliderSection-subTitle">
                  {`Here’s what our`}
                  <br /> customers are
                  <br /> saying
                </h2>
              </div>
            </div>
            <div className="col-md-8">
              <div className="slider">
                <SimpleSlider testimonials={testimonials} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- slider Section --> */}

      {/* <!-- fun facts Section --> */}

      <section className="fun-facts-bg freMont">
        <div className="container">
          <h2 className="title">10 fun facts about Fremont</h2>
        </div>
      </section>

      {/* <!-- fun facts Section --> */}
      {/* <!-- fun facts about Section --> */}

      <section className="funfactsabout-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="funfactsabout-card">
                <div className="number">1</div>
                <p>Fremont, California is celebrating its 52nd anniversary this year. The city was officially formed in 1956.</p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="funfactsabout-card">
                <div className="number">2</div>
                <p>Five communities came together to form Fremont in 1956: Centerville, Irvington, Niles, Mission San Jose, and Warm Springs.</p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="funfactsabout-card">
                <div className="number">3</div>
                <p>
                  Fremont has its very own urban legend – The White Witch of Niles Canyon. There are no early newspaper mentions of her, but the story lives on.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="funfactsabout-card">
                <div className="number">4</div>
                <p>
                  Fremont boasts a silent film theater and museum. The Essanay Film Company, where Charlie Chaplin once worked, was based in Niles.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="funfactsabout-card">
                <div className="number">5</div>
                <p>Olympic gold medalist Kristi Yamaguchi grew up in Fremont.</p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="funfactsabout-card">
                <div className="number">6</div>
                <p>
                  According to the Fremont City website, the record high temperature in Fremont is 106°F, recorded in some July.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="funfactsabout-card">
                <div className="number">7</div>
                <p>
                 Mammoths once roamed Fremont. You can see their fossils at the Wes Gordon Fossil Hall in the Children’s Natural History Museum. The Irvingtonian age is named after Fremont’s Irvington area.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="funfactsabout-card">
                <div className="number">8</div>
                <p>
                 The Niles Canyon Railway is part of a railroad museum offering train rides and the unique experience of renting a caboose.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="funfactsabout-card">
                <div className="number">9</div>
                <p>The California School for the Deaf is located in Fremont, serving the educational needs of the deaf community.</p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="funfactsabout-card">
                <div className="number">10</div>
                <p>
                  Fremont is the southernmost terminus of the BART (Bay Area Rapid Transit) system in the East Bay, making it the end of the line—or the beginning, depending on your perspective!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- fun facts about --> */}

      {/* <!-- start your order Section --> */}

      <section className="startyourorder-Section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="startyourorder_inner">
                <img src="img/Icons-lastSection.png" alt="#" />
                <h2>We make it easy and convenient to reduce your moving footprint</h2>
                <div className="border-vactor"></div>
                <p>
                  A greener, cheaper and more convenient alternative to traditional moving services.
                </p>
                <div className="order-boxes-btn">
                  <button
                    className="btn btn-primary w-100 p-2 d-block aos-init aos-animate"
                    onClick={() => {
                      showZModal();
                      setToggleModal(true);
                    }}>
                    Start Your Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- start your order Section --> */}

      {/* <!-- take your time Section --> */}

      <section className="takeyourtime-Section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="takeyourtime_img">
                <img className="d-none d-md-block" src="img/storage-interface.png" alt="#" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="takeyourtime_text">
                <h2>Take your time to settle in!</h2>
                <p>
                  Don’t rush to unpack. Store your items easily with our Storage On-Demand service.
                  Say goodbye to clutter and enjoy some peace of mind after your move.
                </p>
                <img className="d-block d-md-none" src="img/storage-interface.png" alt="#" />
                <a className="learnmore-btn" onClick={handleLearnMore}>
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- take your time Section Section --> */}

      {/* <!-- zip code Section --> */}

      {/* <section>

        <Container fluid={true} className="choose-landing-page py-5 container-fluid px-5" style={{ paddingTop: '3rem',}}>
        <Row className="align-items-center justify-content-center pt-4">
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
            <p className="hww-head">Moving or need more storage?</p>
           
          </Col>
        </Row>
      </Container>

      </section> */}

      {/* 
      <Container fluid={true} className="px-5 how-we-works-land" style={{ paddingTop: '3rem' }}>
        <Row className="align-items-center justify-content-center pt-4">
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
            <p className="hww-head">Moving or need more storage?</p>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleZipCode}
              onFinishFailed={onFinishFailed}
              autoComplete="off">
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="pb-0">
                  <Form.Item
                    name="service_type"
                    label="Service"
                    rules={[
                      {
                        required: true
                      }
                    ]}>
                    <Select placeholder="Select an option" size="large">
                      <Option value="moving_box">Rent Moving Boxes</Option>
                      <Option value="STORAGE_ON_DEMAND">Storage On-Demand</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.service_type !== currentValues.service_type
                    }>
                    {({ getFieldValue }) =>
                      getFieldValue('service_type') === 'STORAGE_ON_DEMAND' ? (
                        <Form.Item
                          name="delivery_zipcode"
                          label="Zip Code where you’d like storage boxes delivered"
                          rules={[
                            {
                              required: true
                            }
                          ]}>
                          <Input size="large" className="hiw-input" placeholder="Enter Zip code" />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name="pickup_zipcode"
                          label="Zip code of your current address?"
                          rules={[
                            {
                              required: true
                            }
                          ]}>
                          <Input size="large" className="hiw-input" placeholder="Enter Zip code" />
                        </Form.Item>
                      )
                    }
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.service_type !== currentValues.service_type
                    }>
                    {({ getFieldValue }) =>
                      getFieldValue('service_type') === 'moving_box' ? (
                        <Form.Item
                          name="delivery_zipcode"
                          label="Zip code of your new address?"
                          rules={[
                            {
                              required: true
                            }
                          ]}>
                          <Input size="large" className="hiw-input" placeholder="Enter Zipcode" />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>

                  <Form.Item>
                    <Button
                      loading={submitLoader}
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="land-card-btn"
                      block>
                      Start Your Order
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container> */}

      {/* <RenderIf isTrue={props.storeStatus === "open"}>
        <section
          className="hero move-location position-relative location custom "
          style={{
            background: '#FAF9F6'
          }}>
          <div className="container">
            <div id="locations-content" className="ng-scope">
              <div
                className="row align-content-center"
                style={{
                  jstifyContent: 'center'
                }}>
                <div className="columns small-12 large-10 large-offset-1 end" style={{ marginLeft: "15%", marginRight: "15%" }}>
                  <div className="order-box locationform"> */}
      {/* <h2><img width="32" src="img/icon-truck.png" data-interchange="[/assets/img/icon-truck@2x.png, (retina)]" /> Check If We Service Your Area</h2> */}

      {/* <div className="">
                      <div className="">
                        <div className="modal-header">
                          <img src="img/logo.png" className="img-fluid" alt="" />
                          <h5>Where are you moving?</h5> */}
      {/* <button type="button" className="close" onClick={props.hideModal}>
                                                              <span aria-hidden="true">&times;</span>
                                                          </button> */}
      {/* </div>
                        <div className="modal-body">
                          <div className="row">
                            {isError ? <p style={{ color: 'red' }}>{errorMsg} </p> : ''}
                            <div className="col-md-6 p-2">
                              Zip code of your current address?
                              <input
                                type="text"
                                id="ship-address"
                                className="form-control"
                                placeholder="Enter Zip code"
                                name="pickup_zipcode"
                                value={values.pickup_zipcode}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            <div className="col-md-6 p-2">
                              Zip code of your new address?
                              <input
                                type="text"
                                id="pickup-address"
                                className="form-control"
                                placeholder="Enter Zip code"
                                name="delivery_zipcode"
                                value={values.delivery_zipcode}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            <div className="col-12 text-right">
                              <a
                                href="box-package.html"
                                className="mt-4 btn continue-zip-code-btn"
                                onClick={(e) => handleZipCode(e)}>
                                Continue
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
      {/* </Modal> */}
      {/* </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </RenderIf> */}

      <br />
      {/* <!-- we deliver to this area --> */}
    </>
  );
}
Fremont_landing_page.propTypes = {
  setShowHideHeader: PropTypes.any,
  setshowHideFooter: PropTypes.any,
  setshowHideinnerFooter: PropTypes.any,
  zipCodeSection: PropTypes.any,
  scrollToSection: PropTypes.any,
  setshowZipModal: PropTypes.any,
  storeStatus: PropTypes.any,
  storeStatusMessage: PropTypes.any
};

