import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  InfoOutlined
} from '@ant-design/icons';
import { Button, Card, Form, Image, message, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Box from '../../../../assets/images/boxfinal.png';
import './index.css';
import PropTypes from 'prop-types';

import { setBoxCount, setStepCount, setStorageStepCount } from "../../store/actionCreators";
import { useDispatch, useSelector } from 'react-redux';

const StorageDetail = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const currentStep = useSelector(state => state.storage.currentStorageStep);
  const currentStorageStep = useSelector(state => state.storage.currentStorageStep);
  const { boxCount } = useSelector(state => state.storage.order);

  const { isParentNextStepClicked, setParentNextStepClicked, stepRef } = props;

  useEffect(() => {
    if(boxCount) {
      form.setFieldsValue({
        boxCount: boxCount
      })
    }
  }, [boxCount])

  useEffect(() => {
    window.scrollTo({
      top: props?.tabref?.current.offsetTop - 100,
      behavior: 'smooth'
    });
    form.setFieldsValue({
      boxCount: 1
    })
    dispatch(setBoxCount(1));

  }, [])

  useEffect(() => {
    if (isParentNextStepClicked && stepRef == 1) {
      setParentNextStepClicked(false);
      setTimeout(function () {
        onSubmit();
      }.bind(this), 0)
    }
  }, [isParentNextStepClicked])

  useEffect(() => {
    const storedState = localStorage.getItem('storageState');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      
    if (parsedState.order?.boxCount) {
      const boxCount = parsedState.order.boxCount;
      form.setFieldsValue({
        boxCount: boxCount
      })
      dispatch(setBoxCount(boxCount));
    }
    }
  }, []);

  const onSubmit = async () => {
    let formValues = await form.validateFields();
    dispatch(setBoxCount(formValues.boxCount));
    dispatch(setStorageStepCount(currentStep + 1));
  }

  const onBoxSelected=async (value)=>{
    dispatch(setBoxCount(value));
  }

  return (
    <div>
      <div className="steps bg-white" id="steps">
        <h2 className="shopping-cart-header text-white text-center py-2 fs-20 steps-title">
          Step 1: Storage Details
        </h2>
        <Card bordered={false} className="w-100 px-3 pt-3 pb-4">
          <Spin spinning={isFormSubmitted} tip="processing...">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              style={{ position: 'relative', zIndex: 0 }}>
              <Row className="py-4">
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Form.Item
                    name="boxCount"
                    label="How many boxes do you need?"
                    rules={[{ required: true, message: 'Please choose box count' }]}>
                    <Select size="large" placeholder="Select a box count" value={1} defaultValue={boxCount ? boxCount : 1} onChange={onBoxSelected}>
                      {
                        new Array(50).fill(1).map((item, index) => {
                          return (
                            <Select.Option key={index} value={index + 1}>
                              {index + 1} {index == 0 ? 'box' : 'boxes'}
                            </Select.Option>
                          );
                        })
                      }
                    
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="px-3 py-0" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="storage-box-content">
                    <div className="d-flex justify-content-start align-items-center pb-2">
                      <div className="storage-box-image pr-4">
                        <Image src={Box} preview={false} alt={'storage_box'} />
                        <p className="img-spec fw-400 s-color-black">27’’ x 17’’ x 12’’</p>
                      </div>
                      <div className="storage-box-desc s-color-black">
                        <p className="desc-header fw-600 mb-0 pb-2">1 box fits</p>
                        <p className="desc-sub-header fw-400 mb-0">
                          {' '}
                          <CheckOutlined className="tick-icon fw-900" /> 48 books
                        </p>
                        <p className="desc-sub-header fw-400 mb-0">
                          {' '}
                          <CheckOutlined className="tick-icon fw-900" /> 25 wine glasses
                        </p>
                        <p className="desc-sub-header fw-400 mb-0">
                          {' '}
                          <CheckOutlined className="tick-icon fw-900" /> 30-40 plates
                        </p>
                        <p className="desc-sub-header fw-400 mb-0">
                          {' '}
                          <CheckOutlined className="tick-icon fw-900" /> 12-15 pairs of shoes
                        </p>
                        <p className="desc-sub-header fw-400 mb-0">
                          {' '}
                          <CheckOutlined className="tick-icon fw-900" /> Holiday decorations for small office or apartment
                        </p>
                        <p className="desc-sub-header fw-400 mb-0">
                          {' '}
                          <CheckOutlined className="tick-icon fw-900" /> 18,000 Tootsie Rolls
                        </p>
                      </div>
                    </div>

                    <div className="s-content-divider"></div>

                    {/* <div className="stroage-box-info pt-3">
                      <InfoOutlined className="fw-900 info-icon mr-3" />
                      <div className="info-content s-color-black fw-400">
                      Don’t worry! If you’re not exactly sure how many boxes you need our drivers will have additional boxes on hand.
                      </div>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Card>
      </div>

      <div className="row mt-4 mb-10">
        <div className="col  d-flex justify-content-start align-items-center">
          <Button
            className={`btn-previous-step ${currentStep > 0 ? '' : 'd-none'}`}
            icon={<ArrowLeftOutlined />}
            onClick={console.log}
            size={'large'}>
            Previous Step
          </Button>
        </div>
        <div className="col  d-flex justify-content-end align-items-center">
          <Button
            className="btn-next-step"
            icon={<ArrowRightOutlined />}
            loading={isFormSubmitted}
            size={'large'}
            onClick={onSubmit}
          >
            {!isFormSubmitted ? 'Next Step' : 'Submitting..'}
          </Button>
        </div>
      </div>
    </div>
  );
};

StorageDetail.propTypes = {
  tabref: PropTypes.any,
  stepRef: PropTypes.any,
  isParentNextStepClicked: PropTypes.any,
  setParentNextStepClicked: PropTypes.any
}

export default StorageDetail;
